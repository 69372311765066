@font-face {
	font-family: 'bare';
	src:    url('fonts/bare.eot?5me2yy');
	src:    url('fonts/bare.eot?5me2yy#iefix') format('embedded-opentype'),
	url('fonts/bare.ttf?5me2yy') format('truetype'),
	url('fonts/bare.woff?5me2yy') format('woff'),
	url('fonts/bare.svg?5me2yy#bare') format('svg');
	font-weight: normal;
	font-style: normal;
}
// base font size - adjust it to <p> size
$base-font-size: 18px;
$base-font-family: 'News Cycle', sans-serif;
$fancy: 'News Cycle', sans-serif;
$icon: bare;


/* Colors */
$base: #333;
$link: #6a6a6a;
$link_hover: darken( #6a6a6a,10%);
$bgbox: #FCFCFC;
$main: #fbc12d;
$dark: #444444;
$light: #fff;
$border: #737373;
$bg: #fbfbfb;
$bgdark: #ddd;
$header: #3d3d3d;

$green: #3B8130;
$red: #b21f28;

$boxshadow: 0 0 15px -3px $dark;
// Icons

$icon-home: "\e900";
$icon-pencil: "\e90e";
$icon-image: "\e90f";
$icon-phone: "\e901";
$icon-envelop: "\e902";
$icon-location: "\e922";
$icon-calendar: "\e928";
$icon-printer: "\e904";
$icon-floppy-disk: "\e929";
$icon-user: "\e92a";
$icon-search: "\e92c";
$icon-cogs: "\e903";
$icon-menu: "\e906";
$icon-earth: "\e931";
$icon-checkmark: "\e93b";
$icon-circle-right: "\e93d";
$icon-google-plus2: "\e908";
$icon-facebook2: "\e909";
$icon-twitter: "\e905";
$icon-xing: "\e90b";

// Media Queries
$small-screen: 480px !default;
$medium-screen: 768px !default;
$large-screen: 1024px !default;
$xlarge-screen: 1200px !default;
$largeplus-screen: 1450px !default;
$largeultra-screen: 1750px !default;

$screen: "only screen" !default;

$small: "only screen and (min-width: #{$small-screen})" !default;
$small-max: "only screen and (max-width: #{$small-screen+1})";
$medium: "only screen and (min-width:#{$medium-screen})" !default;
$medium-max: "only screen and (max-width: #{$medium-screen+1})";
$large: "only screen and (min-width:#{$large-screen})" !default;
$large-max: "only screen and (max-width: #{$large-screen+1})";
$xlarge: "only screen and (min-width:#{$xlarge-screen})" !default;
$xlarge-max: "only screen and (max-width: #{$xlarge-screen+1})";
$largeplus: "only screen and (min-width: #{$largeplus-screen})";
$largeultra: "only screen and (min-width: #{$largeultra-screen})";

$landscape: "only screen and (orientation: landscape)" !default;
$portrait: "only screen and (orientation: portrait)" !default;

// Zeug fürs Grid
@mixin clearfix() {
	*zoom:1;
	&:before, &:after { content: " "; display: table; }
	&:after { clear: both; }
}

@mixin inline-list {
	margin: 0 auto 0 auto;
	margin-left: -1em;
	margin-right: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;

	& > li {
		list-style: none;
		float: left;
		margin-left: 1em;
		display: block;
		&>* { display: block; }
	}
}
// create placeholder mixin
@mixin placeholder($color, $size:"") {
	&::-webkit-input-placeholder {
		color: $color;
		@if $size != "" {
			font-size: $size;
		}
	}
	&:-moz-placeholder {
		color: $color;
		@if $size != "" {
			font-size: $size;
		}
	}
	&::-moz-placeholder {
		color: $color;
		@if $size != "" {
			font-size: $size;
		}
	}
	&:-ms-input-placeholder {
		color: $color;
		@if $size != "" {
			font-size: $size;
		}
	}
}
@mixin ghostVerticalAlign(){
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%; width: .1px;
	}
}
@mixin tooltip($content: attr(data-title), $direction: top) {
	position: relative;
	&:before, &:after {
		display: none;
		z-index: 98;
	}
	&:hover {
		&:after { // for text bubble
			content: $content;
			display: block;
			position: absolute;
			height: 12px; // (makes total height including padding 22px)
			padding: 6px;
			font-size: 12px;
			white-space: nowrap;
			color: $light;
			@include text-shadow(1px 1px $dark);
			background-color: $main;
		}
		@if ($direction == 'top'){
			&:before {
				@include triangle(down, 6px, $main);
				top: -6px; margin-top: 0;
				left: 47%;
			}
			&:after {
				top: -28px;
				left: 47%; margin-left: -20px;
			}
		}
		@else if ($direction == 'bottom'){
			&:before {
				@include triangle(up, 6px, $main);
				top: auto; margin-top: 0;
				bottom: -6px;
				left: 47%;
			}
			&:after {
				bottom: -28px;
				left: 47%; margin-left: -20px;
			}
		}
	}
}