// Disable Frontend Module Editing
a.btn.jmodedit{
	display: none !important;
}
.moduletable{
	&.filler{
		display: none;
		float: left;
		position: relative;
		top: -17px;
		@media#{$xlarge}{
			display: block;
		}
	}
}
.contact-form{
	max-width: 400px;
	margin: 0 auto;
	text-align: center;
	input, select, textarea{
		margin-bottom: 12px;
	}
	textarea{
		max-width: 100%;
	}
}
.sketchwrap_content_top{
	.moduletable{
		background: $light;
		padding: 12px;
		@media#{$large}{
			padding: 24px;
		}
		float: left;
		width: 100%;
		margin-bottom: 24px;
		 >h3{
			 font-size: 24px;
			 color: $dark;
			 border-bottom: 1px solid $border;
		 }
		a{
			font-weight: normal;
			color: $base;
		}
		.location{
			padding-left: 24px;
			position: relative;
			display: block;
			&:before{
				content: $icon-home;
				font-family: $icon;
				position: absolute;
				left: 0;
			}
		}
	}
}