* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: $link;
	transition: .5s all ease-in-out 0s;
	font-weight: bold;
	&:hover {
		color: $link_hover;
	}
}

h1, h1 * {
	font-size: 32px;
	line-height: 36px;
	@media#{$large}{
		font-size: 44px;
		line-height:50px;
	}
	margin-bottom: 24px;
	margin-top: 0;
	font-weight: normal;
	font-family: $fancy;
	color: $main;
}

h2, h2 * {
	font-size: 26px;
	line-height: 32px;
	margin-bottom: 24px;
	margin-top: 0;
	font-weight: normal;
	font-family: $base-font-family;
	color: $link;
}

h3, h3 * {
	font-size: 20px;
	line-height:24px;
	margin-bottom: 24px;
	margin-top: 0;
	font-family: $base-font-family;
}

h4, h4 * {
	font-weight: bold;
	margin-bottom: 24px;
	margin-top: 0;
	font-family: $base-font-family;
}

p {
	color: $base;
	line-height: 24px;
	margin-bottom: 24px;
	font-family: $fancy;
}

ul, ol {
	margin-left: 24px;
	li {
		line-height: 24px;
	}
}

.item-page {
	ul, ol {
		margin-bottom: 24px;
	}
	a{
		color: darken($main, 5%);
		&:hover{
			color: darken($main, 15%);
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
	height: 1px;
	width: 100%;
	background-color: $dark;
	clear: both;
	margin-bottom: 24px;
}

.item-image {
	.img_caption {
		font-size: 14px;
	}
	&.pull-right {
		float: right;
		max-width: 50%;
		margin-left: 12px;
		margin-bottom: 12px;
		.img_caption {
			text-align: right;
			padding-top: 12px;
		}
	}
	&.pull-left {
		float: left;
		max-width: 50%;
		margin-right: 12px;
		margin-bottom: 12px;
		.img_caption {
			text-align: left;
			padding-top: 12px;
		}
	}
}

/*Artikelinfos*/
dl.article-info {
	font-size: 12px;
	margin-bottom: 12px;
	span {
		font-size: 12px;
	}
	time {
		font-size: 12px;
	}
	dd.createdby {
		font-size: 12px;
		span {
			font-size: 12px;
		}
	}
}

/*Pagination*/

.pagination {
	clear: both;
	float: left;
	width: 100%;
	margin-bottom: 24px;

	p.counter {
		display: none;
		margin-bottom: 0;
		@media #{$large}{
			float: right;
			display: block;
		}
	}
	ul {
		margin-left: 0;
		width: 100%;
		li {
			list-style: none;
			display: inline;
			margin-right: 10px;
			a {
				padding: 5px 10px;
				color: $link;
				background: $light;
				border: 1px solid $dark;
				&:hover {
					color: $link-hover;
					background: darken($light, 10%);
				}
			}
			span {
				padding: 5px 10px;
			}
			&.pagination-end, &.pagination-start {
				display: none;
			}
			&.pagination-next {
				@media #{$large-max}{
					float: right;
					margin-right: 0;
				}
			}
			@media #{$large-max}{
				display: none;
				&.pagination-prev, &.pagination-next {
					display: inline-block;
				}
			}
		}
	}
}

ul.pagenav {
	width: 100%;
	float: left;
	clear: both;
	margin-left: 0;
	list-style: none;
	li {
		display: inline-block;
		a {
			background: $main;
			color: $light;
			padding: 5px;
			font-size: 12px;
			display: block;
			margin-bottom: 12px;
			&:hover {
				background: darken($main, 10%);
			}
		}
	}
	li.pagenav-prev {
		float: left;
		a:before {
			content: '\ea44';
			padding-right: 5px;
			font-family: icomoon;
		}
	}
	li.pagenav-next {
		float: left;
		clear: both;
		@media#{$medium}{
			float: right;
			clear: none;
		}
		a:after {
			content: '\ea42';
			padding-left: 5px;
			font-family: icomoon;
		}
	}
}

input#button6, input.button, .moduletable .button, .btn {
	background: $link;
	color: $light !important;
	padding: 6px 10px;
	display: inline-block;
	transition: .5s all ease 0s;
	margin-bottom: 12px;
	&:hover {
		background: darken($link, 10%);
		color: $light !important;
	}
}

/* Fieldset reset */
fieldset {
	border: 0;
	margin: 0;
	margin-bottom: 24px;
	padding: 0;
	.btn-group {
		float: left;
		label.element-invisible {
			display: none;
		}
		#filter-search {
			min-width: 210px;
			padding: 4px;
			background: $dark;
			color: $light;
			border-radius: 3px;
			font-size: 14px;
		}
		&.pull-right {
			float: right;
			select {
				border: 1px solid $border;
			}
		}
	}
}

.tooltip.fade {
	display: none !important;
}

/* Table */

table {
	width: 100%;
	max-width: 100%;
	color: $dark;
	margin-bottom: 24px;
	table-layout: fixed;
	th {
		background-color: $dark;
		padding: 5px 10px;
		color: $light;
		text-align: left;
		font-size: 12px;
		transition: .5s all ease 0s;
		* {
			color: $light;
			margin-bottom: 0;
		}
		@media #{$medium}{
			font-size: 16px;
		}
	}
	tbody {

		tr {
			transition: .5s background-color ease 0s;
			td {
				padding: 5px 5px;
				vertical-align: top;

				@media#{$medium-max}{
					width: 50% !important;
					display: block;
					float: left;
					word-break: break-word;
				}
				@media#{$small-max}{
					width: 100% !important;
				}
				img {
					margin: 0 auto 24px;
					display: block;
					float: none !important;
				}
				@media#{$small}{
				}
				@media #{$medium}{
					padding: 5px 10px;
				}
				@media#{$large}{
					float: none;
					display: table-cell;
					box-sizing: border-box;
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
				}

				a {
					font-weight: bold;
				}

			}

		}
	}
	&.zebra {
		border: 1px solid $border;
		tr {
			&:nth-child(odd) {
				background-color: $bg;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			&:nth-child(even) {
				background-color: $light;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			td {
				@media#{$medium}{
					border-right: 1px solid $border;
				}
				&:last-child {
					border-right: 0;
				}
				&:first-child {
					padding-left: 5px;
				}
			}
		}
	}
	&.info {
		width: auto;
		@media#{$small-max}{
			width: 100% !important;
		}
		th, th * {
			font-size: 13px;
		}
		tr {
			td {
				font-size: 13px;
				* {
					font-size: 13px;
				}
				&:first-child, &:last-child {
					padding: 5px 5px;
					@media #{$medium}{
						padding: 5px 10px;
					}
				}
			}
		}
	}
	&.sticky {
		min-width: 724px;
		tr {
			td {
				@media#{$medium-max}{
					width: auto !important;
					display: table-cell;
					float: none;
					border-right: 1px solid $border;
					word-break: normal;
				}
				@media#{$small-max}{
					width: auto !important;
				}
			}
		}
	}
	&.stacked {
		width: 100% !important;
		tr {

			border-bottom: 1px solid $link_hover;
			margin-bottom: 1px;
			padding-bottom: 1px;
			&:first-child {
				td {
					font-weight: bold;
					* {
						margin-bottom: 0;
					}
				}
			}
			> td {
				padding: 5px !important;
				text-align: center;
				* {
					margin-bottom: 0;
					text-align: center;
				}
			}
			td:first-child {
				font-weight: bold;
				padding: 5px 10px;
				background: darken($bg, 10%);
			}
			td:nth-child(2) {
				background: lighten(#ddd, 10%)
			}
			td:nth-child(3) {
				background: lighten(#ddd, 5%)
			}
			td:nth-child(4) {
				background: #ddd;
			}
		}
	}
	&.category {

	}
	&.dfContactTable {
		tr th {
			width: 54px;
			padding: 5px 10px;
			text-align: center;
		}
	}
	&.center{
		tr td{
			text-align: center;
			img{
				border: 1px solid $link;
			}
		}
	}
}
img{
	&.thumb{
		border: 1px solid $link;
	}
}
.sketch_grid {
	margin-bottom: 24px;
	&.tleft {
		float: left;
	}
}

// Inputs
input {
	&[type='text'], &[type='password'], &[type='email'] {
		border: 1px solid $border;
		padding: 2px;
	}
}

textarea {
	border: 1px solid $border;
	padding: 2px;
}

select {
	border: 1px solid $border;
}

// In Item-page
// Article Summary
.article-index {
	background: $bg;
	padding: 12px;
	margin-bottom: 24px;
	&.pull-right {
		float: right;
	}
	&.pull-left {
		float: left;
	}
}

.pagenavcounter {
	float: right;
	background: $dark;
	color: $light;
	border-radius: 8px;
	padding: 4px 8px;
	clear: right;
	margin-bottom: 24px;
}

.pager {
	ul {
		width: 100%;
		float: left;
		clear: both;
		margin-top: 24px;
		li {
			padding-left: 0;
			&:first-child {
				float: left;
			}
			&:last-child {
				float: right;
			}
		}
	}
}

.alert {
	a.close {
		cursor: pointer;
	}
	&.alert-message {
		background-color: rgba(255, 255, 255, .92);
		padding: 12px;
		overflow: hidden;
		float: left;
		width: 100%;
		margin-bottom: 24px;
		border: 2px solid $red;
		a.close {
			border: 1px solid $red;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			line-height: 17px;
			display: block;
			text-align: center;
			color: $red;
			float: right;
		}
		p {
			margin-bottom: 6px;
		}
	}
	&.alert-warning, &.alert-error {
		background-color: rgba(255, 255, 255, .92);
		padding: 12px;
		overflow: hidden;
		float: left;
		width: 100%;
		margin-bottom: 24px;
		border: 2px solid $red;
		a.close {
			border: 1px solid $red;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			line-height: 17px;
			display: block;
			text-align: center;
			color: $red;
			float: right;
		}
		p {
			margin-bottom: 6px;
		}
	}
	&.alert-success, &.alert-notice {
		background-color: rgba(255, 255, 255, .92);
		padding: 12px;
		overflow: hidden;
		float: left;
		width: 100%;
		margin-bottom: 24px;
		border: 2px solid $green;
		a.close {
			border: 1px solid $green;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			line-height: 17px;
			display: block;
			text-align: center;
			color: $green;
			float: right;
		}
		p {
			margin-bottom: 6px;
		}
	}
}

// Base Styling Inputs

input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea {
	border: 1px solid $border;
}

// Icons for Base Elements
a[href^="mailto:"] {
	&:before {
		content: $icon-envelop;
		font-family: $icon;
		padding-right: 6px;
	}
}

a[href^="tel:"] {
	&:before {
		content: $icon-phone;
		font-family: $icon;
		padding-right: 6px;
	}
}
.fax{
	&:before{
		content: $icon-printer;
		font-family: $icon;
		padding-right: 6px;
	}
}

*[data-title] {
	cursor: pointer;
	&:hover {
		&:after {
			content: attr(data-title);
			padding: 4px 8px;
			color: #333;
			position: absolute;
			left: 12px;
			top: 82%;
			font-size: 14px;
			max-width: 500px;
			text-transform: initial;
			z-index: 20;
			box-shadow: 0 0 4px #222;
			background: $bg;
		}
	}
}