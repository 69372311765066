.com_sketchglossary{
	.item{
		.category{
			color: $main;
		}
		a{
			&:before{
				content: $icon-earth;
				font-family: $icon;
				padding-right: 6px;
			}
			&.glos_readmore{
				&:before{
					content: $icon-circle-right;
				}
			}
		}
	}
}