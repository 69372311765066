.sketchwrap_slider {
	.slider {
		.sketchslider_pro-wrap {
			.sketchslider_pro {
				overflow: hidden;
				margin-bottom: 24px;
				@media#{$large}{
					max-height: 270px;
				}
				@media#{$largeplus}{
					max-height: 330px;
				}
				@media#{$largeultra}{
					max-height: 400px;
				}
				background: $base;
				.box.left {
					left: auto;
					right: 72px;
					top: 24px;
					bottom: auto;
					@media#{$large}{
						max-width: 400px;
					}
					p {
						font-weight: normal;
					}
				}
				img {
					@media#{$large-max}{
						transform: none !important;
					}
				}
			}
		}
	}
}