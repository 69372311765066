#login-form {
	.userdata {
		label {
			font-size: 14px;
			min-width: 96px;
			display: inline-block;
		}
		input {
			border: 1px solid grey;
			margin-bottom: 12px;
			font-size: 14px;
		}
		ul.unstyled {
			li {
				a {
					font-size: 14px;
				}
			}
		}
	}
}

.login, .profile, .profile-edit, .logout, .reset, .remind {
	background: $light;
	padding: 12px;
	width: 100%;
	float: left;
	margin-bottom: 24px;
	display: block;
	ul.btn-toolbar{
		list-style: none;
		margin-left: 0;
		li{
			margin-right: 12px;
			margin-bottom: 12px;
			display: inline-block;
			&:last-child{
				margin-right: 0;
			}
		}
	}
	@media#{$large}{
		padding: 24px;
	}
	.control-group, dl{
		width: 100%;
		float: left;
		clear: both;
		margin-bottom: 6px;
		.control-label, .controls, dd, dt{
			width: 100%;
			float: left;
			display: block;
			margin-bottom: 12px;
			@media#{$medium}{
				width: 50%;
			}
		}
	}
	fieldset{
		border: 1px solid $border;
		padding: 12px;
		legend{
			padding: 0 6px;
			font-size: 14px;
			font-weight: bold;
			font-family: $base-font-family;
		}
	}
	.chzn-container * {
		font-family: $base-font-family;
	}
}
ul.nav.nav-tabs.nav-stacked {
	float: left;
	margin-bottom: 24px;
}