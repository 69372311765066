.blog, .blog-featured{
	a{
		color: darken($main, 5%);
		&:hover{
			color: darken($main, 15%);
		}
	}
	.page-header h1{
		text-align: center;
		text-shadow: 0 0 1px #000;
	}
	.item, .leading-item{
		background: $light;
		padding: 12px;
		width: 100%;
		float: left;
		margin-bottom: 24px;
		@media#{$large}{
			padding: 24px;
		}
		img{
			display: block;
			margin: 0 auto;
			float: none;
			border: 1px solid $link;
		}
	}
	.items-more{
		margin-bottom: 24px;
	}
	.items-more{
		background: $light;
		padding: 12px;
		@media#{$large}{
			padding: 24px;
		}
		float: left;
		width: 100%;
		margin-bottom: 24px;
		ol{
			li{
				a{

				}
			}
		}
	}
}
.category-list{
	background: $light;
	padding: 12px;
	@media#{$large}{
		padding: 24px;
	}
	width: 100%;
	clear: both;
	margin-bottom: 24px;
	.cat-children{
		> div{
			a:before{
				content: $icon-circle-right;
				font-family: $icon;
				padding-right: 6px;
			}
		}
	}
}