div.top_right {
	.moduletable {
		ul.nav {
			margin-top: 10px;
			margin-bottom: 10px;
			float: right;
			li {
				display: inline;
				list-style: none;
				margin-left: 12px;
				@media#{$xlarge}{
					margin-left: 24px;
				}
				&:first-child {
					margin-left: 0;
				}
				a {
					font-size: 12px;
					color: $light;
				}
			}
		}
	}
}

.menuwrap {
	background: $base;
}

nav.top_menu {
	.moduletable {
		float: left;
		overflow: visible;
		&.slidenav1 {
			padding: 0;
		}
		ul.nav.menu {
			margin-left: 0;
			margin-bottom: 0;
			background-color: $base;
			li {
				display: inline-block;
				list-style: none;
				position: relative;
				&:last-child {
					border-right: 0;
				}
				&:first-child {
					a {
						border-left: 1px solid $border;
					}
				}
				&.parent {
					a {
						&:after {
							content: '>';
							font-family: monospace;
							position: absolute;
							transform: rotate(90deg);
							left: 50%;
							bottom: -3px;
						}
					}
				}
				a {
					color: darken($light, 20%);
					padding: 11px 6px;
					transition: .6s all ease 0s;
					text-decoration: none;
					display: block;
					font-size: 15px;
					font-weight: normal;
					text-transform: uppercase;
					font-family: $base-font-family;
					border-right: 1px solid $border;
					@media#{$xlarge}{
						padding: 11px 19px;
						font-size: 14px;
					}
				}
				&:hover > a {
					color: rgba($main, .7);
					text-shadow: 0 0 4px $link_hover;
				}
				ul.nav-child {
					position: absolute;
					top: 30px;
					left: 0;
					transition: .5s background ease 0s;
					margin-left: 0;
					width: auto;
					visibility: hidden;
					opacity: 0;
					background: rgba($main, .7);
					z-index: 99;
					min-width: 152px;
					box-shadow: 7px 0px 20px -10px #000;
					li {
						display: block;
						float: none;
						margin-right: 0;
						&:first-child a {
							border-left: 0;
						}
						a {
							font-size: 14px;
							padding: 8px 10px;
							line-height: 17px;
							background: $main;
							text-transform: none;
							letter-spacing: 0;
							font-family: $fancy;
							font-weight: normal;
							color: $base;
							border-right: 0;
							&:hover {
								background: darken($main, 10%);
								color: $light;
							}
							&:after {
								content: '';
							}
							&:first-child {
								border-left: 0;
							}
						}
						&.active {
							a {
								background: $main;
								color: $light;
							}
						}
						&.deeper.parent {
							> a {
								padding-right: 24px;
								position: relative;
								&:after {
									content: ' >';
									transform: none;
									position: absolute;
									left: auto;
									bottom: auto;
									right: 6px;
								}
							}
						}
						> ul.nav-child{
							left: 100%;
						}
						&:hover {
							> ul.nav-child {
								visibility: visible;

								top: 0;
							}
						}
					}
				}
				&:hover {
					> ul.nav-child {
						opacity: 1;
						display: block;
						visibility: visible;
						top: 46px;
					}
				}
				&.active {
					a {
						background: $main;
						color: $light;
					}
					ul {
						li {
							a {
							}
						}
					}
				}
			}
		}
	}
}

nav.bottom {
	.moduletable {
		ul.nav {
			margin-left: 0;
			li {
				display: inline;
				list-style: none;
				margin-right: 12px;
				@media#{$xlarge}{
					margin-right: 24px;
				}
				&:last-child {
					margin-right: 0;
				}
				a {
					font-size: 14px;
					color: $light;
				}
			}
		}
	}
}

.slidenav1, .slidenav2, .slidenav3, .slidenav4 {
	display: none;
	@media #{$large}{
		display: block;
	}
}

#main-nav {
	display: block;
	float: left;
	font-size: 32px;
	color: $link;
	font-weight: normal;
	padding: 6px;
	@media #{$large}{
		display: none;
	}
	&:before {
		content: $icon-menu;
		font-family: $icon;
	}
}