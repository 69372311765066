* {
	font-size: $base-font-size;
	font-family: $fancy;
}

body {
	background-color: $bgdark;
}

.allwrap {
	overflow-x: hidden;
}

.top_line {
	position: fixed;
	width: 100%;
	background: #eaeaea;
	z-index: 999;
	box-shadow: $boxshadow;
	p.single_line {
		display: none;
		@media#{$small}{
			display: block;
		}
		margin-top: 9px;
		margin-bottom: 10px;
		text-align: right;
		a {
			font-weight: normal;
		}
		.home {
			display: none;
			font-weight: normal;
			@media#{$medium}{
				display: inline-block;
			}
			&:before {
				content: $icon-home;
				font-family: $icon;
				padding-right: 6px;
			}
		}
	}
}

header {
	width: 100%;
	margin-top: 44px;
	background: $header;
	.logo {
		p {
			font-weight: 400;
		}
	}
	.headerwrap.box {
		float: left;
		width: 100%;
		margin-top: 41px;

		.logotext1 {
			color: $main;
			font-size: 32px;
			@media#{$medium}{
				font-size: 42px;
			}
			margin-bottom: 12px;
			text-shadow: 0 0 6px #000;
		}
		.logotext2 {
			color: $main;
			font-size: 22px;
			text-shadow: 0 0 6px #000;
			@media#{$medium}{
				font-size: 28px;
			}
		}
	}
}

main {
	margin-top: 24px;
	.item-page {
		background: $light;
		padding: 12px;
		width: 100%;
		float: left;
		margin-bottom: 24px;
		display: block;
		@media#{$large}{
			padding: 24px;
		}
	}
}

footer {
	width: 100%;
	float: left;
	clear: both;
	background: $base;
	padding-top: 24px;
	padding-bottom: 24px;
	a, h3 {
		color: $main;
	}
	a:hover {
		color: darken($main, 10%);
	}
	p {
		color: $light;
	}
	a[href^="tel:"] {
		color: $light;
		font-weight: normal;
	}
	.location{
		padding-left: 24px;
		position: relative;
		display: block;
		&:before{
			content: $icon-home;
			font-family: $icon;
			position: absolute;
			left: 0;
		}
	}
}