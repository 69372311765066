ul.sigProContainer {
	text-align: center;
	max-width: 100%;
	li.sigProThumb {
		float: none;
		display: inline-block;
		margin: 0;
		max-width: 100%;
		&:before{
			content: '';
			display: none;
		}
		span.sigProLinkOuterWrapper{
			span.sigProLinkWrapper{
				background: $link;
				border-color: $link_hover;
				transition: .3s all ease 0s;
				padding: 2px;
			}
		}
		&:hover{
			span.sigProLinkOuterWrapper{
				span.sigProLinkWrapper{
					background: $link_hover;
				}
			}
		}
		a.sigProLink {
			overflow: hidden;
			border-color: $link_hover;
			max-width: 100%;
		}
	}
	li.sigProClear{
		&:before{
			content: '';
			display: none;
		}
	}
	span.sigProCaption{
		bottom: 0;
		left: 0;
	}
	span.sigProPseudoCaption{
		width: 100%;
		left: 0;
		bottom: 0;
	}
	&.singleThumbGallery{
		margin: 0 auto !important;
		float: none !important;
		li.sigProThumb {
			span.sigProLinkWrapper{
				transition: .3s all ease 0s;
			}
			a.sigProLink{
				@media#{$small-max}{
					width: 234px !important;
				}
			}
			&:hover{
				span.sigProLinkWrapper{
					background: $main;
					border-color: $dark;
				}
			}
		}

	}
}